<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="listaSitio">Tipo de sitio</label>
          <select
            class="form-control form-control-sm"
            v-model="form.sitio_type"
            :class="$v.form.sitio_type.$invalid ? 'is-invalid' : 'is-valid'"
            @change="resetSitios()"
          >
            <option value="">Seleccione...</option>
            <option
              v-for="listaSitio in listasForms.listaSitios"
              :key="listaSitio.numeracion"
              :value="listaSitio.numeracion"
            >
              {{ listaSitio.descripcion }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div v-if="form.sitio_type == 1" class="form-group">
          <label for="sitio">Sitio</label>
          <v-select
            :class="[
              $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
              $store.getters.getDarkMode ? 'dark-vselect' : '',
            ]"
            v-model="sitio"
            placeholder="Sitio"
            label="nombre"
            class="form-control form-control-sm p-0"
            :options="listasForms.sitios"
            :filterable="false"
            @search="buscarSitios"
            @input="selectSitio()"
          ></v-select>
        </div>
        <div v-if="form.sitio_type == 2" class="form-group">
          <label for="punto_gestion">Punto de Gestión</label>
          <v-select
            :class="[
              $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
              $store.getters.getDarkMode ? 'dark-vselect' : '',
            ]"
            v-model="punto_gestion"
            placeholder="Punto Gestión"
            label="nombre"
            class="form-control form-control-sm p-0"
            :options="listasForms.puntos_gestion"
            :filterable="false"
            @search="buscarPuntosGestion"
            @input="selectPuntoGestion()"
          ></v-select>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for="tiempo">Tiempo (Minutos)</label>
          <input
            type="number"
            class="form-control form-control-sm"
            id="tiempo"
            min="0"
            v-model="form.tiempo"
            :class="$v.form.tiempo.$invalid ? 'is-invalid' : 'is-valid'"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for="orden">Orden</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="orden"
            disabled="true"
            v-model="form.orden"
            :class="$v.form.orden.$invalid ? 'is-invalid' : 'is-valid'"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <br />
          <button
            v-show="!$v.form.$invalid"
            type="button"
            @click="cargar()"
            class="btn btn-success"
            data-html="true"
            title="Guardar punto de gestion"
            data-toggle="tooltip"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div id="tabla puntos">
      <div class="card-body p-0">
        <div class="col-md-12 form-group">
          <table
            id="tabla-rutas"
            class="table table-bordered table-striped table-hover table-sm"
          >
            <thead>
              <tr>
                <th style="width: 100px">Orden</th>
                <th style="width: 200px">Tipo de sitio</th>
                <th>Sitio</th>
                <th style="width: 100px">Tiempo</th>
                <th style="width: 150px">Eliminar</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rutaSitio, index) in rutasSitio"
                :key="rutaSitio.posicion"
              >
                <td>
                  <span v-if="formActualizar && idActualizar == index">
                    <!-- Formulario para actualizar -->
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="orden"
                      v-model="form2.orden"
                      :class="
                        $v.form2.orden.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </span>
                  <span v-else>
                    <!-- Dato nombre -->
                    {{ rutaSitio.pivot.orden }}
                  </span>
                </td>
                <td>
                  <span v-if="formActualizar && idActualizar == index">
                    <!-- Formulario para actualizar -->
                    <select
                      class="form-control form-control-sm"
                      v-model="form2.sitio_type"
                      :class="
                        $v.form2.sitio_type.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @change="resetSitios()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="listaSitio in listasForms.listaSitios"
                        :key="listaSitio.numeracion"
                        :value="listaSitio.numeracion"
                      >
                        {{ listaSitio.descripcion }}
                      </option>
                    </select>
                  </span>
                  <span v-else>
                    <!-- Dato nombre -->
                    {{
                      rutaSitio.pivot.sitio_type == "App\\Sitio"
                        ? "Sitio"
                        : "Punto de gestion"
                    }}
                  </span>
                </td>
                <td>
                  <span v-if="formActualizar && idActualizar == index">
                    <!-- Formulario para actualizar -->
                    <div v-if="form2.sitio_type == 1" class="form-group">
                      <v-select
                        :class="[
                          $v.form2.sitio_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="sitio"
                        placeholder="Sitio"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.sitios"
                        :filterable="false"
                        @search="buscarSitios"
                        @input="selectSitio()"
                      >
                      </v-select>
                    </div>
                    <div v-if="form2.sitio_type == 2" class="form-group">
                      <v-select
                        :class="[
                          $v.form2.sitio_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="punto_gestion"
                        placeholder="Punto Gestión"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.puntos_gestion"
                        :filterable="false"
                        @search="buscarPuntosGestion"
                        @input="selectPuntoGestion()"
                      ></v-select>
                    </div>
                  </span>
                  <span v-else>
                    <!-- Dato nombre -->
                    {{ rutaSitio.nombre }}
                  </span>
                </td>
                <td>
                  <span v-if="formActualizar && idActualizar == index">
                    <!-- Formulario para actualizar -->
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="tiempo"
                      v-model="form2.tiempo"
                      :class="
                        $v.form2.tiempo.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </span>
                  <span v-else>
                    <!-- Dato nombre -->
                    {{ rutaSitio.pivot.tiempo }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="formActualizar && idActualizar == index">
                    <button
                      v-show="!$v.form2.$invalid"
                      type="button"
                      class="btn btn-sm bg-success"
                      @click="guardarActualizacion(index)"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                  </span>
                  <span v-else>
                    <!-- Botón para mostrar el formulario de actualizar -->
                    <button
                      type="button"
                      class="btn btn-sm bg-navy"
                      @click="verFormActualizar(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <!-- Botón para borrar -->
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="destroy(index)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 form-group text-right">
          <button
            type="button"
            title="Guardar Cambios"
            class="btn btn-sm bg-primary"
            @click="save()"
          >
            <i class="fas fa-save"></i>
            <div>
              <span><small>Guardar Cambios</small></span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  name: "RutaSitio",
  components: {
    vSelect,
  },
  data() {
    return {
      form: { sitio_id: null, orden: null },
      form2: { sitio_id: null, orden: null, tiempo: null },
      idActual: null,
      idAnterior: null,
      idSuperior: null,
      nombre_sitio: null,
      nombre_punto: null,
      formActualizar: false,
      sitiosAsignados: [],
      puntosGestionAsignados: [],
      idActualizar: -1,
      listaOrden: [],
      sitio: [],
      punto_gestion: [],
      ruta_id: null,
      rutasSitio: {},
      listasForms: {
        sitios: [],
        puntos_gestion: [],
      },
    };
  },
  validations: {
    form: {
      sitio_type: {
        required,
      },
      sitio_id: {
        required,
      },
      tiempo: {
        required,
      },
      orden: {
        required,
      },
    },
    form2: {
      sitio_type: {
        required,
      },
      sitio_id: {
        required,
      },
      tiempo: {
        required,
      },
      orden: {
        required,
      },
    },
  },
  methods: {
    getIndex() {
      this.form.ruta_id = this.$route.params.id;
      this.ruta_id = this.form.ruta_id;
      axios
        .get("/api/admin/rutas/show/" + this.form.ruta_id)
        .then((response) => {
          this.rutasSitio = response.data;
          this.form.orden = this.rutasSitio.length + 1;
          this.getAsignados();
        });
    },

    // Se consulta los sitios y puntos de gestión ya asignados
    getAsignados() {
      this.sitiosAsignados = [];
      this.puntosGestionAsignados = [];
      this.rutasSitio.forEach((element) => {
        if (element.pivot.sitio_type == "App\\PuntoGestion") {
          this.puntosGestionAsignados.push(element.pivot.sitio_id);
        } else {
          this.sitiosAsignados.push(element.pivot.sitio_id);
        }
      });
    },

    // Consulto los tipos de sitio
    getListaSitio() {
      axios.get("/api/lista/25").then((response) => {
        this.listasForms.listaSitios = response.data;
      });
    },

    // Se busca los sitios disponibles
    buscarSitios(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url, {
            params: {
              estado: 1,
              not_id: this.sitiosAsignados,
            },
          })
          .then(function(response) {
            me.listasForms.sitios = response.data;
            loading(false);
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectSitio() {
      this.form.sitio_id = "";
      this.form2.sitio_id = "";
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
        this.form2.sitio_id = this.sitio.id;
        this.nombre_sitio = this.sitio.nombre;
      }
    },

    // Se busca los puntos de gestión disponibles
    buscarPuntosGestion(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/puntos_gestion/lista?punto_gestion=" + search;
        axios
          .get(url, {
            params: {
              estado: 1,
              not_id: this.puntosGestionAsignados,
            },
          })
          .then(function(response) {
            me.listasForms.puntos_gestion = response.data;
            loading(false);
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectPuntoGestion() {
      this.form.sitio_id = "";
      this.form2.sitio_id = "";
      if (this.punto_gestion) {
        this.form.sitio_id = this.punto_gestion.id;
        this.form2.sitio_id = this.punto_gestion.id;
        this.nombre_punto = this.punto_gestion.nombre;
      }
    },

    resetSitios() {
      this.form.sitio_id = null;
      this.form2.sitio_id = null;
      this.sitio = [];
      this.listasForms.sitios = [];
      this.punto_gestion = [];
      this.listasForms.puntos_gestion = [];
    },

    cargar() {
      if (!this.$v.form.$invalid) {
        if (this.form.sitio_type == 1) {
          this.form.sitio_type = "App\\Sitio";
        } else {
          this.form.sitio_type = "App\\PuntoGestion";
        }
        if (this.form.sitio_type == "App\\Sitio") {
          this.rutasSitio.push({
            pivot: this.form,
            nombre: this.nombre_sitio,
            posicion: this.form.orden,
          });
          this.form = {
            orden: this.rutasSitio.length + 1,
            ruta_id: this.ruta_id,
            sitio_id: null,
          };
        } else {
          this.rutasSitio.push({
            pivot: this.form,
            nombre: this.nombre_punto,
            posicion: this.form.orden,
          });
          this.form = {
            orden: this.rutasSitio.length + 1,
            ruta_id: this.ruta_id,
            sitio_id: null,
          };
        }
        this.getAsignados();
      }
    },

    save() {
      axios
        .put("/api/admin/rutas/assingOrRevokeSitio", {
          ruta_id: this.ruta_id,
          arreglo: this.rutasSitio,
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Se actualizaron los datos de la ruta exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    verFormActualizar: function(ruta_id) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizar = ruta_id;
      this.form2.orden = this.rutasSitio[ruta_id].pivot.orden;
      this.form2.sitio_id = this.rutasSitio[ruta_id].pivot.sitio_id;
      if (this.rutasSitio[ruta_id].pivot.sitio_type == "App\\Sitio") {
        this.form2.sitio_type = 1;
        this.sitio = {
          id: this.rutasSitio[ruta_id].pivot.sitio_id,
          nombre: this.rutasSitio[ruta_id].nombre,
        };
      } else {
        this.form2.sitio_type = 2;
        this.punto_gestion = {
          id: this.rutasSitio[ruta_id].pivot.sitio_id,
          nombre: this.rutasSitio[ruta_id].nombre,
        };
      }
      this.form2.tiempo = this.rutasSitio[ruta_id].pivot.tiempo;
      // Mostramos el formulario
      this.formActualizar = true;
    },

    guardarActualizacion: function(ruta_id) {
      // Ocultamos nuestro formulario de actualizar
      this.formActualizar = false;
      // Actualizamos los datos
      this.rutasSitio[ruta_id].pivot.orden = this.form2.orden;
      this.rutasSitio[ruta_id].pivot.sitio_id = this.form2.sitio_id;
      if (this.form2.sitio_type == 1) {
        this.rutasSitio[ruta_id].pivot.sitio_type = "App\\Sitio";
        this.rutasSitio[ruta_id].nombre = this.sitio.nombre;
      } else {
        this.rutasSitio[ruta_id].pivot.sitio_type = "App\\PuntoGestion";
        this.rutasSitio[ruta_id].nombre = this.punto_gestion.nombre;
      }
      this.rutasSitio[ruta_id].pivot.tiempo = this.form2.tiempo;
      this.rutasSitio[ruta_id].posicion = this.form2.orden;
    },

    destroy: function(ruta_id) {
      this.$swal({
        title: "Está seguro de quitar el este sitio de la Ruta?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        if (result.value) {
          // Borramos de la lista
          let rutica = ruta_id + 1;
          for (let i = rutica; i < this.rutasSitio.length; i++) {
            this.rutasSitio[i].pivot.orden = this.rutasSitio[i].pivot.orden - 1;
          }
          this.rutasSitio.splice(ruta_id, 1);
          this.form.orden = this.rutasSitio.length + 1;
        }
      });
    },
  },

  mounted() {
    this.getListaSitio();
    this.getIndex();
  },
};
</script>
